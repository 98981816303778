import { Link } from 'react-router-dom';
import axios from 'axios';
import { Nav, Button, ListGroup, Row, Col, CloseButton, Offcanvas } from 'react-bootstrap';
import { currencySymbol, publicApiUrl } from '../config';
import SalePrice from './SalePrice';

const CartPanel = ({ cartIsOpen, cart, ctxDispatch }) => {
  const updateCartHandler = async (item, quantity, variant) => {
    const { data } = await axios.get(`${publicApiUrl}/products/${item._id}`);
    let hasError = false;
    if (item.hasVariants) {
      const foundItem = data.variants[variant];
      hasError = foundItem.countInStock < quantity;
    } else {
      hasError = data.countInStock < quantity;
    }
    if (hasError) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity, cartIsOpen: true },
    });
  };
  const removeItemHandler = (item) => {
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
  };

  return (
    <Offcanvas
      className="bg-dark"
      placement="end"
      variant="dark"
      show={cartIsOpen} onHide={() => ctxDispatch({ type: 'CLOSE_CART' })}
      >
        <Offcanvas.Header className="text-white">
          <Offcanvas.Title>Cart </Offcanvas.Title>
          <CloseButton className="float-end text-white" variant="white" onClick={() => ctxDispatch({ type: 'CLOSE_CART' })} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row style={{ height: "85%"}}>
            <ListGroup style={{ height: "90%", overflow: "auto" }}>
              {cart.cartItems.map((item) => (
                <ListGroup.Item
                  as="li"
                  style={{ background: "none" }}
                  className="text-white d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="me-auto">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="img-fluid rounded img-thumbnail"
                      />
                    </div>
                    <div className="mx-2">
                      <div className="mx-2">
                        {item.name} {item.variant && item.variant.toUpperCase()}
                        <br />
                        <SalePrice item={item} />

                      </div>
                      <span>
                        <Button
                          style={{ background: "none", border: "none" }}
                          className="mx-0 bg-none border-none"
                          onClick={() =>
                            updateCartHandler(item, item.quantity - 1, item.variant)
                          }
                          disabled={item.quantity === 1}
                        >
                          <i className="fas fa-minus-circle"></i>
                        </Button>{' '}
                        <span>{item.quantity}</span>{' '}
                        <Button
                          style={{ background: "none", border: "none" }}
                          className="mx-0 bg-none border-none"
                          onClick={() =>
                            updateCartHandler(item, item.quantity + 1, item.variant)
                          }
                          disabled={item._idVariant ? item.quantity === item.variants[item.variant].countInStock : item.quantity === item.countInStock}
                        >
                          <i className="fas fa-plus-circle"></i>
                        </Button>
                      </span>

                    </div>
                  </div>
                  <Button
                    style={{ color: "red", background: "none", border: "none" }}
                    onClick={() => removeItemHandler(item)}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Row>

          <Row className="mx-2" style={{ height: "50px"}}>
            <h5 className="text-white">
              Subtotal ({cart.cartItems.reduce((a, c) => a + c.quantity, 0)}{' '}
              items) : {currencySymbol}
              {cart.cartItems.reduce((a, c) => a + (c.salePrice ? c.salePrice * c.quantity : c.price * c.quantity), 0)}
            </h5>
            <Button className="bottom p-1" onClick={() => { window.location.href = '/cart'; }}>View Cart</Button>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
  )
};

export default CartPanel;
