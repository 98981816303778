import { Link, useLocation } from 'react-router-dom';
import { useEffect, useReducer, useState, useContext } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Product from '../components/Product';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import Carousel from '../components/Carousel';
import { Store } from '../Store';
import { loginCallback, publicApiUrl } from '../config';
import { checkAuth } from '../utils';
import { Button, Container } from 'react-bootstrap';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const HomeScreen = () => {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: '',
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const urlPath = useLocation();

  useEffect(() => {
    checkAuth({
      urlPath,
      userInfo,
      ctxDispatch,
      toast,
      callbackUrl: loginCallback,
      shouldRedirect: false,
    });
  }, []);
  // const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get(`${publicApiUrl}/products`);
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }

      // setProducts(result.data);
    };
    fetchData();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Golf Talk</title>
      </Helmet>
      <Carousel />
      <section className="page-section">
        <Container>
          <Row className="my-5">
            <Col md={4}>
              <h1>&nbsp; Shop with <br /> &nbsp; &nbsp; &nbsp; Confidence</h1>
              <div className="p-4">
                <p className="text-muted">
                  We are based in Ph but the products we sell come from different countries with 100% authenticity guaranteed.
                </p>
                <Button className="float-end" variant="light">
                  <Link to="/shop-with-confidence" className="nav-link">
                    Learn More
                  </Link>

                </Button>
              </div>
            </Col>
            <Col md={8}>
              <img className="img-fluid rounded" src="/images/shopping.jpg" alt="shopping" />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <h1>Featured Products</h1>
          <div className="products">
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <Row>
                {products.map((product) => (
                  <Col key={product.slug} sm={6} xs={6} md={4} lg={3} className="mb-3">
                    <Product product={product}></Product>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </Container>
      </section>
      <hr />
      <section className="page-section">
        <Container>
          <Row className="my-5 text-center">
            <Col md={3} xs={6} className="my-3">
              <img style={{height: "60px"}} className="text-center img-fluid" src="/images/logo/fj.png" alt="shopping" />
            </Col>
            <Col md={3} xs={6} className="my-3">
              <img style={{height: "140px", marginTop: "-30px"}} className="img-fluid" src="/images/logo/golf-pride.png" alt="shopping" />
            </Col>
            <Col md={3} xs={6} className="my-3">
              <img style={{height: "80px"}} className="img-fluid" src="/images/logo/nike.png" alt="shopping" />
            </Col>
            <Col md={3} xs={6} className="my-3">
              <img style={{height: "50px"}} className="img-fluid" src="/images/logo/titleist.png" alt="shopping" />
            </Col>

            </Row>
            <Row className="text-center">
            <Col md={3} xs={6} className="my-3">
              <img style={{height: "50px"}} className="img-fluid" src="/images/logo/taylormade.png" alt="shopping" />
            </Col>
            <Col md={3} xs={6} className="my-3">
              <img style={{height: "120px", marginTop: "-30px"}} className="img-fluid" src="/images/logo/ecco.png" alt="shopping" />
            </Col>
            <Col md={3} xs={6} className="my-5">
              <img style={{height: "50px", marginTop: "-20px"}} className="img-fluid" src="/images/logo/ping.png" alt="shopping" />
            </Col>
            <Col md={3} xs={6} className="my-5">
              <img style={{height: "80px", marginTop: "-50px"}} className="img-fluid" src="/images/logo/puma.png" alt="shopping" />
            </Col>

          </Row>
        </Container>
      </section>
    </div>
  );
}
export default HomeScreen;
