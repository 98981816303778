import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';

const AboutUsScreen = () => {
  return (
    <Container className="small-container">
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <h1 className="my-3">About Us</h1>
      <p>about my company</p>
    </Container>
  );
}

export default AboutUsScreen;
