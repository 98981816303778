import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import axios from 'axios';
import { useState, useContext } from 'react';
import { Store } from '../Store';
import { currencySymbol, publicApiUrl } from '../config';
import SalePrice from './SalePrice';

function Product(props) {
  const { product } = props;
  let productCount = 0;
  product.hasVariants && Object.keys(product.variants).forEach((item) => {
    productCount += Number(product.variants[item].countInStock);
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    cart: { cartItems },
  } = state;

  const addToCartHandler = async (item) => {
    setIsDisabled(true);

    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`${publicApiUrl}/products/${item._id}`);
    if (data.countInStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity, cartIsOpen: true },
    });
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };

  return (
    <Card>
      <Link to={`/product/${product.slug}`}>
        <img src={product.image} className="card-img-top mt-2 " alt={product.name} />
      </Link>
      <Card.Body>
        <Link to={`/product/${product.slug}`}>
          <Card.Title>{product.name}</Card.Title>
        </Link>
        {/* <Rating rating={product.rating} numReviews={product.numReviews} /> */}
        <Card.Text><SalePrice item={product} /></Card.Text>
        { product.hasVariants && productCount === 0 ?
          <Button variant="light" disabled>
            Out of stock
          </Button> : <></>
        }
        { !product.hasVariants && product.countInStock === 0 ? (
          <Button variant="light" disabled>
            Out of stock
          </Button>
        ) : (
          <></>
          // Remove button so user will have to go to product screen to select the variants
          // <Button  disabled={isDisabled} onClick={() => addToCartHandler(product)}>
          //   {isDisabled ? (
          //     <>
          //       <i className="fa fa-spinner" /> Adding...
          //     </>
          //   ) : "Add to cart"
          //   }
          // </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default Product;
