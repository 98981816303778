import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ignoreShipping } from '../config';

export default function CheckoutSteps(props) {
  return (
    <Row className="checkout-steps">
      <Col className={props.step1 ? 'active' : ''}>Sign In</Col>
      {!ignoreShipping ? <Col className={props.step2 ? 'active' : ''}>Shipping</Col> : <></>}
      {/* <Col className={props.step3 ? 'active' : ''}>Payment</Col> */}
      <Col className={props.step3 ? 'active' : ''}>Review Order</Col>
    </Row>
  );
}
