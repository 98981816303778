import { currencySymbol } from "../config";

const SalePrice = ({ item }) => {
  return (
    item.salePrice ?
      <>
        <span className="sale-price" >{currencySymbol}{item.price}</span>
        <span className="text-danger">&nbsp; {currencySymbol}{item.salePrice}</span>
      </>
    :
      <>{currencySymbol}{item.price}</>
  );
};

export default SalePrice;
