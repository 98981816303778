import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';

const ContactUsScreen = () => {
  return (
    <Container className="small-container">
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <h1 className="my-3">Contact Us</h1>
      <p>
      Any concerns, comments, or questions are welcome. Please contact us below:
      </p>
      <p><i className="fa fa-phone" />&nbsp;1800-16516453 (PLDT)</p>
      <p><i className="fa fa-phone" />&nbsp;1800-86516453 (GLOBE)</p>
      <p><i className="fa fa-envelope" />&nbsp;email@google.com</p>
      
    </Container>
  );
}

export default ContactUsScreen;
