import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Breadcrumb  } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import { currencySymbol, orderStatus, orderStatusInfo, privateApiUrl, loginLink, ignoreShipping } from '../config';
import Shipping from '../components/Shipping';
import AddNotes from '../components/AddNotes';
import SalePrice from '../components/SalePrice';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    case 'ORDER_STATUS_REQUEST':
      return { ...state, loadingOrderStatus: true };
    case 'ORDER_STATUS_SUCCESS':
      return { ...state, loadingOrderStatus: false, successOrderStatus: true };
    case 'ORDER_STATUS_FAIL':
      return { ...state, loadingOrderStatus: false };
    case 'ORDER_STATUS_RESET':
      return {
        ...state,
        loadingOrderStatus: false,
        successOrderStatus: false,
      };
    default:
      return state;
  }
}
export default function AdminOrderScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();

  const [
    {
      loading,
      error,
      order,
      loadingOrderStatus,
      successOrderStatus,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
  });
  const [status, setStatus] = useState(order.status);
  const [statusDisable, setStatusDisable] = useState(false);

  const handleStatusChange = async (e) => {
    setStatus(e);
    setStatusDisable(true);
    try {
      dispatch({ type: 'ORDER_STATUS_REQUEST' });
      const { data } = await axios.put(
        `${privateApiUrl}/orders/${order._id}/status/${e}`,
        {},
        {
          headers: { authorization: `Bearer ${userInfo.id_token}` },
        }
      );
      dispatch({ type: 'ORDER_STATUS_SUCCESS', payload: data });
      toast.success('Order status updated');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'ORDER_STATUS_FAIL' });
    }
    setTimeout(() => setStatusDisable(false), 5000);
  }

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`${privateApiUrl}/orders/${orderId}`, {
          headers: { authorization: `Bearer ${userInfo.id_token}` },
        });
        setStatus(data.status);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    if (!userInfo) {
      window.location.replace(loginLink);
      return;
    }
    if (
      !order._id ||
      successOrderStatus ||
      (order._id && order._id !== orderId)
    ) {
      fetchOrder();
      if (successOrderStatus) {
        dispatch({ type: 'ORDER_STATUS_RESET' });
      }
    }
  }, [
    order,
    userInfo,
    orderId,
    navigate,
    successOrderStatus,
  ]);


  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <>
      <Helmet>
        <title>Order {orderId}</title>
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item href="/admin/orders">Orders</Breadcrumb.Item>
        <Breadcrumb.Item active>{orderId}</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="my-3">Order {orderId}</h1>
      <Row>
        <Col md={8}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Status</Card.Title>
              {userInfo['custom:isAdmin'] && (
                <Form.Select
                disabled={statusDisable}
                value={status}
                onChange={(e) => handleStatusChange(e.target.value)}>
                  {orderStatus.map((item) => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </Form.Select>
              )}
              {
                order.updatedStatusBy &&
                <span style={{fontSize: "12px"}}>
                  Status updated by: {order.updatedStatusBy} <br/>
                  on {new Date(order.statusDate).toString()}
                </span>
              }

              <Card className="m-2 p-2">
                <Card.Text style={{ fontSize: "12px", color: "gray" }}>
                  Note: <br />
                  {orderStatusInfo.map((item, index) => (
                    <span key={`${item.name}`}>
                      <strong>{item.name}</strong> - {item.description}<br />
                    </span>
                  ))}
                </Card.Text>
              </Card>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <AddNotes disabled={true} title="Buyer's Notes" notes={order.notes} />
        </Col>
        <Col md={8}>
          <Shipping order={order} userInfo={userInfo} />
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Payment</Card.Title>
              <Card.Text>
                <strong>Method:</strong> {order.paymentMethod} <br />
                {order.isPaid ? (
                  <>
                    <strong>Status:</strong> {order.paymentResult.status} <br />
                    <strong>Transaction #:</strong> {order.paymentResult.id}
                  </>
                ) : (
                  <>
                    <strong>Status:</strong> PENDING
                  </>
                )}
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Items</Card.Title>
              <ListGroup variant="flush">
                {order.orderItems.map((item, index) => (
                  <ListGroup.Item key={`${item._id}-${index}`}>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="img-fluid rounded img-thumbnail"
                        ></img>{' '}
                        <br />
                        <Link to={`/product/${item.slug}`}>{item.name} {item.variant && item.variant.toUpperCase()}</Link>
                      </Col>
                      <Col md={3}>
                        Quantity: <span>{item.quantity}</span>
                      </Col>
                      <Col md={3}><SalePrice item={item} /></Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Order Summary</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Items</Col>
                    <Col>{currencySymbol}{order.itemsPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Shipping</Col>
                    <Col>{currencySymbol}{order.shippingPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Tax</Col>
                    <Col>{currencySymbol}{order.taxPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <strong> Order Total</strong>
                    </Col>
                    <Col>
                      <strong>{currencySymbol}{order.totalPrice.toFixed(2)}</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
