import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../utils';
import { Container, Breadcrumb, Row, Col, Accordion } from 'react-bootstrap';
import { ListGroup, Form, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Button from 'react-bootstrap/Button';
import { privateApiUrl, publicApiUrl } from '../config';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};
export default function ProductEditScreen() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: productId } = params;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [price, setPrice] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [image, setImage] = useState('');
  const [live, setLive] = useState(false);
  const [gender, setGender] = useState('UNISEX');
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState('');
  const [brand, setBrand] = useState('');
  const [description, setDescription] = useState('');
  const [isImageAdded, setIsImageAdded] = useState(false);
  const [imageFile, setImageFile] = useState('');
  const [imageFileUpload, setImageFileUpload] = useState('');
  const [hasVariants, setHasVariants] = useState(false);
  const [variantsAttributes, setVariantsAttributes] = useState([]);
  const [variantName, setVariantName] = useState('');
  const [variantValues, setVariantValues] = useState('');
  const [productVariants, setProductVariants] = useState({});

  useEffect(() => {
    if (loadingUpload) {
      return;
    }
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`${publicApiUrl}/products/${productId}`);
        setName(data.name);
        setSlug(data.slug);
        setPrice(data.price);
        setSalePrice(data.salePrice);
        setLive(data.live);
        setGender(data.gender === 'NONE' ? 'UNISEX' : data.gender);
        setImage(data.image);
        setImages(data.images);
        setCategory(data.category.toLowerCase());
        setCountInStock(data.countInStock);
        setBrand(data.brand);
        setDescription(data.description);
        setHasVariants(data.hasVariants);
        setProductVariants(data.variants);
        setVariantsAttributes(data.variantsAttributes);

        dispatch({ type: 'FETCH_SUCCESS' });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [productId, loadingUpload]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (hasVariants && variantsAttributes.length === 0) {
      toast.error('Please add variants or uncheck the variant section');
      return;
    }
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios.put(
        `${privateApiUrl}/products/${productId}`,
        {
          _id: productId,
          name,
          slug,
          price,
          salePrice,
          live,
          gender,
          image,
          images,
          category,
          brand,
          countInStock,
          description,
          hasVariants: hasVariants,
          variantsAttributes,
          variants: productVariants,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.id_token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      toast.success('Product updated successfully');
      navigate('/admin/products');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };
  const selectFileHandler = async (e, forImages) => {
    const file = e.target.files[0];
    const [selectedFile] = e.target.files;
    setImageFile(URL.createObjectURL(file));
    setIsImageAdded(true);
    setImageFileUpload(selectedFile);
  }
  const submitImage = async () => {
    if (!isImageAdded) {
      return;
    }
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.get(`${privateApiUrl}/products/${productId}/uploadUrl`, {
        headers: {
          authorization: `Bearer ${userInfo.id_token}`,
        },
      });
      const uploadResult = await axios({
        method: 'put',
        url: data.uploadUrl,
        data: imageFileUpload,
        headers: { 'Content-Type': 'image/jpg' },
      });
      dispatch({ type: 'UPLOAD_SUCCESS' });
      setImage(data.photoUrl);
      toast.success('Image uploaded successfully. click Update to apply it');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
    setImageFile('');
  };
  const deleteFileHandler = async (fileName, f) => {
    setImages(images.filter((x) => x !== fileName));
    toast.success('Image removed successfully. click Update to apply it');
  };
  const handleAddVariant = async () => {
    let hasError = false;
    if (!variantName || !variantValues) {
      return;
    }
    variantsAttributes.forEach((item) => {
      if (item.name === variantName) {
        hasError = true;
      }
    });
    if (hasError) {
      toast.error('Variant already exist');
      return;
    }
    setVariantsAttributes([ ...variantsAttributes, { name: variantName, values: variantValues.trim() }]);
    const variantsAttributesCopy = [ ...variantsAttributes, { name: variantName, values: variantValues }];
    const allVariants = variantsAttributesCopy.map(item => item.values.split(',').map(x => x.trim()));
    const names = allVariants.reduce(
      (a,b)=>a.flatMap(x=>b.map(y=>x+'_'+y)));

    const pV = {};
    names.forEach((item) => {
      pV[item] = {
        name: item,
        countInStock: 0,
      };
    });
    setProductVariants(pV);
    setVariantName('');
    setVariantValues('');
  };
  const handleDeleteVariants = async () => {
    setVariantsAttributes([]);
    setProductVariants({});
  };
  const handleVariantCount = async (count, name) => {
    const pV = { ...productVariants };
    pV[name].countInStock = count;
    setProductVariants(pV);
  }
  return (
    <Container className="large-container">
      <Helmet>
        <title>Edit Product {productId}</title>
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item href="/admin/products">Products</Breadcrumb.Item>
        <Breadcrumb.Item active>{productId}</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Edit Product {productId}</h1>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Form onSubmit={submitHandler}>
          <Row>
            <Col md={5} className="d-flex justify-content-center"><img style={{height: "350px"}} src={image} /></Col>
            <Col md={5} >
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="live">
                <Form.Label>Live</Form.Label>
                <Form.Check
                  type="switch"
                  checked={live}
                  onChange={(e) => setLive(e.target.checked)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="slug">
                <Form.Label>Slug</Form.Label>
                <Form.Control
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>


          <Row>
            <Col md={10}>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Sale Price</Form.Label>
                <Form.Control
                  type="number"
                  value={salePrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}>
                  <option value="UNISEX">UNISEX</option>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="brand">
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <Form.Group className="mb-3" controlId="varaiants">
                <Form.Label>Include Variants</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={hasVariants}
                  onChange={(e) => setHasVariants(e.target.checked)}
                />
              </Form.Group>
              {
                hasVariants &&
                <>
                  <Form.Label>Variant Name</Form.Label>
                  <Form.Control
                    value={variantName}
                    placeholder="ex: size"
                    onChange={(e) => setVariantName(e.target.value.toLowerCase())}
                  />
                  <Form.Label>Variant Values</Form.Label>
                  <Form.Control
                    value={variantValues}
                    placeholder="ex: small, medium, large, xlarge"
                    onChange={(e) => setVariantValues(e.target.value.toLowerCase())}
                  />
                  <Button variant="dark" className="mt-2 mx-2 " onClick={handleDeleteVariants} >Reset variants</Button>
                  <Button className="mt-2" onClick={handleAddVariant} >Add variant</Button>

                  <br />
                  { variantsAttributes.length > 0 &&
                    <Accordion className="mt-2">
                      <Accordion.Item>
                        <Accordion.Header>
                          Variants Count:
                        </Accordion.Header>
                          {
                            Object.keys(productVariants).map((item, index) => (
                              <Accordion.Body className="m-4" key={index}>
                                <Card>
                                  <Card.Body key={index}>
                                    {item}
                                    <div>
                                      Count in Stock:
                                      <Form.Control
                                        type="number"
                                        min="0"
                                        step="1"
                                        value={productVariants[item].countInStock}
                                        onChange={(e) => handleVariantCount(e.target.value, item)}
                                      /></div>
                                  </Card.Body>
                                </Card>
                              </Accordion.Body>
                            ))
                          }
                      </Accordion.Item>
                    </Accordion>
                  }
                </>
              }
            </Col>
            {
              !hasVariants &&
                <Col md={5}>
                  <Form.Group className="mb-3" controlId="countInStock">
                    <Form.Label>Count In Stock</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      step="1"
                      value={countInStock}
                      onChange={(e) => setCountInStock(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
            }
          </Row>
          {/* <Form.Group className="mb-3" controlId="image">
            <Form.Label>Image File</Form.Label>
            <Form.Control
              value={image}
              hidden
              onChange={(e) => setImage(e.target.value)}
              required
            />
            <img src={imageFile} />
          </Form.Group> */}
          {/* <Form.Group className="mb-3" controlId="imageFile">
            <Form.Label>Upload Main Image</Form.Label>
            <Form.Control type="file" onChange={selectFileHandler} />
            <Button onClick={submitImage}>Upload</Button>
            {loadingUpload && <LoadingBox></LoadingBox>}
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="additionalImage">
            <Form.Label>Images</Form.Label>
            {images.length === 0 && <MessageBox>No image</MessageBox>}
            <ListGroup variant="flush">
              <Row>
              {images.map((x) => (
                <Col key={x} md={3}>
                  <img src={x} style={{ height: "200px"}} alt={x} className="img-thumbnail" />
                  <br />
                  <Button variant="danger" onClick={() => deleteFileHandler(x)}><i className="fa fa-trash" /> Delete</Button>
                  <Form.Check
                    type="radio"
                    label="Select as main photo"
                    name="main-image"
                    checked={image === x}
                    onChange={() =>
                      setImage(x)}
                  />
                </Col>
              ))}
              </Row>
            </ListGroup>
          </Form.Group>
          <Row>
            <Col md="5">
              <Form.Group className="mb-3" controlId="additionalImageFile">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => selectFileHandler(e, true)}
                />
                <img src={imageFile} style={{maxHeight: '300px'}} className="img-fluid pt-2" />
                <div>
                  <Button disabled={!imageFile} className="float-end" variant="secondary" onClick={submitImage}>Upload</Button>
                </div>
                {loadingUpload && <LoadingBox></LoadingBox>}
              </Form.Group>
            </Col>
          </Row>


          <div className="mb-3">
            <Button className="float-end" variant="primary" disabled={loadingUpdate} type="submit">
              Update
            </Button>
            {loadingUpdate && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  );
}
