import { Badge, Button, NavDropdown, Nav, Navbar, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import SearchBox from './SearchBox';
import { useEffect, useState, useContext } from 'react';
import { getError } from '../utils';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';
import { publicApiUrl, loginLink, joinUsLink, authTokenUrl, refreshParameterBody } from '../config';
import { Store } from '../Store';

const Header = ({
  userInfo, cart,
  setSidebarIsOpen, sidebarIsOpen, signoutHandler,
}) => {
  const { dispatch: ctxDispatch } = useContext(Store);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(`${publicApiUrl}/products/categories`);
        setCategories(data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchCategories();
    if (!userInfo) {
      return;
    }
    const { exp, refresh_token } = userInfo;
    const todayTime = new Date().getTime();
    const fetchRefreshToken = async () => {
      const options = {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: `${refreshParameterBody}&refresh_token=${refresh_token}`,
        url: authTokenUrl,
      };
      const { data } = await axios(options);
      var decoded = jwt_decode(data.id_token);
      const userInfoData = {
        ...userInfo,
        ...data,
        ...decoded,
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfoData));
      ctxDispatch({ type: 'USER_SIGNIN', payload: userInfoData });
    }
    if (exp * 1000 < todayTime) {
      fetchRefreshToken();
    }
  }, []);
  const adminLinks = () => {
    return (
      <NavDropdown title="Admin" id="admin-nav-dropdown">
        <LinkContainer to="/admin/dashboard">
          <NavDropdown.Item onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>Dashboard</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/admin/products">
          <NavDropdown.Item onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>Products</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/admin/orders">
          <NavDropdown.Item onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>Orders</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/admin/users">
          <NavDropdown.Item onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>Users</NavDropdown.Item>
        </LinkContainer>
      </NavDropdown>
    );
  };
  const ProfileDropdown = () => {
    return (
    <NavDropdown title="Profile" id="basic-nav-dropdown">
      <LinkContainer to="/profile">
        <NavDropdown.Item onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>User Profile</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/user/orderhistory">
        <NavDropdown.Item onClick={() => setSidebarIsOpen(!sidebarIsOpen)}>Order History</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Divider />
      <Link
        className="dropdown-item"
        to="/"
        onClick={signoutHandler}
      >
        Sign Out
      </Link>
    </NavDropdown>);
  };

  const HamburgerMenuButton = () => {
    return (
      <Button
        id="navbar-button"
        className="float-end"
        variant="dark"
        onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
      >
        <i className={sidebarIsOpen ? 'fas fa-chevron-right' : 'fas fa-bars'}></i>
      </Button>
    )
  }

  const Cart = () => {
    return (
      <Link to="/cart" className="nav-link" onClick={() => setSidebarIsOpen(false)}>
        <i className="bi bi-cart"></i>
        {cart.cartItems.length > 0 && (
          <Badge pill bg="danger" style={{fontSize: '12px'}}>
            {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
          </Badge>
        )}
      </Link>
    )
  }
  const navGenderLinks = [
    {
      label:'MEN',
      name: 'MALE',
    },
    {
      label: 'WOMEN',
      name: 'FEMALE',
    }];
  return (
    <header>
      {/* Upper headerbar */}
      <Navbar className="p-0 m-0 bg-light" bg="light" expand="lg">
        <Container>
          <Navbar.Collapse>
            <Nav style={{ fontSize: '12px' }} className="me-auto  w-100  justify-content-end">
              <Nav.Link href="/contactus">Contact Us</Nav.Link>
              <span className="p-2">|</span>

              {userInfo ? (
                ProfileDropdown()
              ) : (
                <>
                  <Nav.Link href={joinUsLink}>Join Us</Nav.Link>
                  <span className="p-2">|</span>
                  <Nav.Link href={loginLink}>Sign In</Nav.Link>
                </>
              )}
              {userInfo && userInfo['custom:isAdmin'] && adminLinks() }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* menu when its compressed - smaller screens */}
      <div
        className={
          sidebarIsOpen
            ? 'bg-dark side-bar-pannel active-nav side-navbar d-flex justify-content-between flex-wrap flex-column'
            : 'bg-dark side-bar-pannel side-navbar d-flex justify-content-between flex-wrap flex-column'
        }>
        <Nav className="flex-column w-100 p-2">
          <div>
            {HamburgerMenuButton()}
          </div>

          {navGenderLinks.map((gender) => (
            <Nav.Item className="w-100" key={gender.name}>
              <LinkContainer to={`/search?category=all&gender=${gender.name}`}>
                <Nav.Link onClick={() => setSidebarIsOpen(!sidebarIsOpen)} className="text-white">{gender.label}</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            ))}
          <Nav className="me-auto  w-100">
            <SearchBox />
            {Cart()}
          </Nav>
          <Nav.Link className="text-white" href="/contactus">Contact Us</Nav.Link>
            {userInfo ? (
              ProfileDropdown()
            ) : (
              <>
                <Nav.Link className="text-white" href={joinUsLink}>Join Us</Nav.Link>
                <Nav.Link className="text-white" href={loginLink}>Sign In</Nav.Link>
              </>
            )}
            {userInfo && userInfo['custom:isAdmin'] && adminLinks() }
        </Nav>
      </div>
      {/* Main header bar */}
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand onClick={() => setSidebarIsOpen(false)}>Golf Talk</Navbar.Brand>
          </LinkContainer>
          <Nav className="ms-auto d-lg-none justify-content-end mx-2">
            <Nav.Item className="mt-0 pt-0">
            {Cart()}
            </Nav.Item>
          </Nav>
          {HamburgerMenuButton()}
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse >
            <Nav className="me-auto  w-100  justify-content-end">
              {categories.map((category) => (
                <Nav.Item key={category}>
                  <LinkContainer to={`/search?category=${category}`}>
                    <Nav.Link>{category.toUpperCase()}</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              ))}
            </Nav>
            <Nav className="me-auto  w-100  justify-content-end">
              <SearchBox />
              {Cart()}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
