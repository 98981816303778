import { Card } from 'react-bootstrap';
import { ignoreShipping } from '../config';

const Shipping = ({ order, userInfo }) => {
  return ignoreShipping ? (
    <>
      {order.isPaid ?
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>Shipping</Card.Title>
          <Card.Text>
            <strong>Name:</strong> {order.paymentResult.shipping.name.full_name} <br />
            <strong>Email:</strong> {order.paymentResult.payer.email_address} <br />
            <strong>Address: </strong> {order.paymentResult.shipping.address.address_line_1} &nbsp;
            {order.paymentResult.shipping.address.admin_area_2} {order.paymentResult.shipping.address.admin_area_1},&nbsp;
            {order.paymentResult.shipping.address.country_code} {order.paymentResult.shipping.address.postal_code}
          </Card.Text>
        </Card.Body>
      </Card>
      :
      <></>
      }

    </>
  ) : (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Shipping</Card.Title>
        <Card.Text>
          <strong>Name:</strong> {order.shippingAddress.fullName} <br />
          <strong>Email:</strong> {order.userEmail} <br />
          <strong>User ID:</strong> {order.user} <br />
          <strong>Address: </strong> {order.shippingAddress.address},
          {order.shippingAddress.city}, {order.shippingAddress.postalCode}
          , {order.shippingAddress.country}
          &nbsp;
          {order.shippingAddress.location &&
            order.shippingAddress.location.lat && (
              <a
                target="_new"
                href={`https://maps.google.com?q=${order.shippingAddress.location.lat},${order.shippingAddress.location.lng}`}
              >
                Show On Map
              </a>
            )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Shipping;
