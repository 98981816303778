import { createContext, useReducer } from 'react';

export const Store = createContext();

const initialState = {
  fullBox: false,
  cartIsOpen: false,
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,

  cart: {
    shippingAddress: localStorage.getItem('shippingAddress')
      ? JSON.parse(localStorage.getItem('shippingAddress'))
      : { location: {} },
    paymentMethod: 'Paypal',
    // paymentMethod: localStorage.getItem('paymentMethod')
    //   ? localStorage.getItem('paymentMethod')
    //   : '',
    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
  },
};
function reducer(state, action) {
  switch (action.type) {
    case 'SET_FULLBOX_ON':
      return { ...state, fullBox: true };
    case 'SET_FULLBOX_OFF':
      return { ...state, fullBox: false };
    case 'CLOSE_CART':
      return { ...state, cartIsOpen: false };
    case 'CART_ADD_ITEM':
      const newItem = action.payload;
      let cartItems;
      if (newItem.variant) {
        const existItem = state.cart.cartItems.find(
          (item) => item._idVariant === newItem._idVariant
        );
        cartItems = existItem
          ? state.cart.cartItems.map((item) =>
              item._idVariant === existItem._idVariant ? newItem : item
            )
          : [...state.cart.cartItems, newItem];
      } else {
        const existItem = state.cart.cartItems.find(
          (item) => item._id === newItem._id
        );
        cartItems = existItem
          ? state.cart.cartItems.map((item) =>
              item._id === existItem._id ? newItem : item
            )
          : [...state.cart.cartItems, newItem];
      }

      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return {
        ...state,
        cart: { ...state.cart, cartItems },
        cartIsOpen: action.payload.cartIsOpen,
      };
    case 'CART_REMOVE_ITEM': {
      let cartItems;
      if (action.payload._idVariant) {
        cartItems = state.cart.cartItems.filter(
          (item) => !item._idVariant || item._idVariant !== action.payload._idVariant
        );
      } else {
        cartItems = state.cart.cartItems.filter(
          (item) => item._id !== action.payload._id
        );
      }

      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return { ...state, cart: { ...state.cart, cartItems } };
    }
    case 'CART_CLEAR':
      return { ...state, cart: { ...state.cart, cartItems: [] } };

    case 'USER_SIGNIN':
      return { ...state, userInfo: action.payload };
    case 'USER_SIGNOUT':
      return {
        ...state,
        userInfo: null,
        cart: {
          cartItems: [],
          shippingAddress: {},
          // paymentMethod: '',
        },
      };
    case 'SAVE_SHIPPING_ADDRESS':
      return {
        ...state,
        cart: {
          ...state.cart,
          shippingAddress: action.payload,
        },
      };
    case 'SAVE_SHIPPING_ADDRESS_MAP_LOCATION':
      return {
        ...state,
        cart: {
          ...state.cart,
          shippingAddress: {
            ...state.cart.shippingAddress,
            location: action.payload,
          },
        },
      };

    case 'SAVE_PAYMENT_METHOD':
      return {
        ...state,
        cart: { ...state.cart, paymentMethod: action.payload },
      };
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children} </Store.Provider>;
}
