import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { Col, Row } from 'react-bootstrap';

const ShopWithConfidenceScreen = () => {
  return (
    <Container>
      <Helmet>
        <title>Shop with Confidence</title>
      </Helmet>
      <Row>
      <h1 className="my-3">Shop with Confidence</h1>
      <Col md={6}><img className="img-fluid rounded" src="/images/shopping.jpg" /></Col>
      <Col md={6}>
      <p>We are based in Ph but the products we sell come from different countries with 100% authenticity guaranteed.</p>
      </Col>

      </Row>

    </Container>
  );
}

export default ShopWithConfidenceScreen;
