import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="page-footer font-small bg-dark pt-5">
      <div className="container-fluid text-center text-md-left">
      <div className="row p-5 mx-5">
        <div className="col-md-3 mb-md-0 mb-3">
          <Nav.Link className="text-light" href="./aboutus">About Us</Nav.Link>
        </div>
        <div className="col-md-3 mb-md-0 mb-3">
          <Nav.Link className="text-light" href="./contactus">Contact Us</Nav.Link>
        </div>
        <div className="col-md-3 mb-md-0 mb-3">
          <Nav.Link className="text-light" href="#contactus">Policy</Nav.Link>
        </div>
        <div className="col-md-3 mb-md-0 mb-3">
          <Nav.Link className="text-light" href="/contactus">FAQS</Nav.Link>
        </div>
      </div>
      </div>
      <div className="footer-copyright text-center text-light py-3">© 2022 Copyright: &nbsp;
        <Link className="text-light" to="https://klipa.app">klipa.app</Link>
      </div>
    </footer>
  );
};

export default Footer;
