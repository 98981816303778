import axios from 'axios';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Col, Card, ListGroup, Badge, Button, Breadcrumb } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Reviews from '../components/Reviews';
import { Store } from '../Store';
import { getError } from '../utils';
import Rating from '../components/Rating';
import { currencySymbol, publicApiUrl } from '../config';
import { toast } from 'react-toastify';
import SalePrice from '../components/SalePrice';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REFRESH_PRODUCT':
      return { ...state, product: action.payload };
    case 'CREATE_REQUEST':
      return { ...state, loadingCreateReview: true };
    case 'CREATE_SUCCESS':
      return { ...state, loadingCreateReview: false };
    case 'CREATE_FAIL':
      return { ...state, loadingCreateReview: false };
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, product: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function ProductScreen() {
  let reviewsRef = useRef();
  const [selectedImage, setSelectedImage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;
  const [variants, setVariants] = useState({});
  const [variantsItem, setVariantsItem] = useState([]);
  const [productCount, setProductCount] = useState(0);

  const [{ loading, error, product, loadingCreateReview }, dispatch] =
    useReducer(reducer, {
      product: [],
      loading: true,
      error: '',
    });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get(`${publicApiUrl}/products/slug/${slug}`);
        let productCount = 0;
        console.log(result.data);
        if (result.data.hasVariants) {
          Object.keys(result.data.variants).forEach((item) => {
            productCount += Number(result.data.variants[item].countInStock);
          });
        } else {
          productCount = result.data.countInStock;
        }
        setProductCount(productCount);
        dispatch({ type: 'FETCH_SUCCESS', payload: {
          ...result.data,
          productCount,
        }});

      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };
    fetchData();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const addToCartHandler = async () => {
    if (product.hasVariants) {
      let hasError = false;
      let error = '';
      product.variantsAttributes.forEach((item) => {
        if (!variants[item.name]) {
          error = `Please select ${item.name}`;
          hasError = true;
        }
      });
      if (hasError) {
        toast.error(error);
        return;
      }
    }
    let existItem;
    if (product.hasVariants) {
      product._idVariant = product._id + '-' + variantsItem.join('_');
      existItem = cart.cartItems.find((x) => x._idVariant === product._idVariant);
    } else {
      existItem = cart.cartItems.find((x) => x._id === product._id);
    }
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`${publicApiUrl}/products/${product._id}`);
    let hasError = false;
    if (product.hasVariants) {
      const item = variantsItem.join('_').trim();
      const foundItem = product.variants[item];
      hasError = foundItem.countInStock < quantity;
    } else {
      hasError = data.countInStock < quantity;
    }
    if (hasError) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    setIsDisabled(true);
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: {
        ...product,
        quantity,
        ...(product.hasVariants && { _idVariant: product._idVariant }),
        ...(product.hasVariants && { variant: variantsItem.join('_').trim()}),
        cartIsOpen: true,
      },
    });
    setTimeout(() => setIsDisabled(false), 1000);
  };
  const handleVariantAttributeChange = async (value, name, index) => {
    setVariants({
      ...variants,
      [name]: value,
    });
    const variantsItemCopy = [...variantsItem];
    variantsItemCopy[index] = value;
    setVariantsItem(variantsItemCopy);
  }
  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Row>
        <Col md={12}>
          <Breadcrumb style={{ textTransform: "uppercase" }}>
            <Breadcrumb.Item href="/search">Products</Breadcrumb.Item>
            <Breadcrumb.Item href={`/search?category=${product.category}`}>{product.category}</Breadcrumb.Item>
            <Breadcrumb.Item active>{product.name}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col md={6}>
          <img
            className="img-large"
            src={selectedImage || product.image}
            alt={product.name}
          ></img>
        </Col>
        <Col md={3}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Helmet>
                <title>{product.name}</title>
              </Helmet>

              <h1>{product.name}</h1>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <Rating
                rating={product.rating}
                numReviews={product.numReviews}
              ></Rating>
            </ListGroup.Item> */}
            <ListGroup.Item>Price : <SalePrice item={product} /></ListGroup.Item>
            <ListGroup.Item>
              <Row xs={3} md={2} className="g-2">
                {[...product.images].map((x) => (
                  <Col key={x}>
                    <Card>
                      <Button
                        className="thumbnail"
                        type="button"
                        variant="light"
                        onClick={() => setSelectedImage(x)}
                      >
                        <Card.Img variant="top"  src={x} alt="product" />
                      </Button>
                    </Card>
                  </Col>
                ))}
              </Row>
            </ListGroup.Item>
            {
              product.hasVariants &&
              <ListGroup.Item>
                {
                  product.variantsAttributes.map((item, index) => (
                    <Form.Select
                      key={index}
                      value={variants[item.name]}
                      onChange={(e) => handleVariantAttributeChange(e.target.value, item.name, index)}>
                        <option>{item.name}</option>
                        {item.values.split(',').map((value, indexj) => (
                          <option key={indexj} value={value}>{value}</option>
                        ))}
                      </Form.Select>
                  ))
                }
              </ListGroup.Item>
            }
            <ListGroup.Item>
              Description:
              <p>{product.description}</p>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Price:</Col>
                    <Col><SalePrice item={product} /></Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Status:</Col>
                    <Col>
                        {product.productCount > 0 ? (
                          <Badge bg="success">In Stock</Badge>
                        ) : (
                          <Badge bg="danger">Unavailable</Badge>
                        )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                {productCount > 0 && (
                  <ListGroup.Item>
                    <div className="d-grid">
                      <Button disabled={isDisabled} onClick={addToCartHandler} variant="primary">
                        {isDisabled ? "Adding to Cart..." : "Add to Cart"}
                      </Button>
                    </div>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Reviews reducer={reducer} userInfo={userInfo} reviewsRef={reviewsRef} /> */}
    </div>
  );
}
export default ProductScreen;
