const authUrl = process.env.REACT_APP_AUTH_URL;
const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
const loginCallback = process.env.REACT_APP_LOGIN_CALLBACK;
const placeOrderLink = process.env.REACT_APP_PLACE_ORDER_LINK;
const shippingLink = process.env.REACT_APP_SHIPPING_LINK;
const loginLink = `${authUrl}/login?client_id=${clientId}&response_type=code&redirect_uri=${loginCallback}`;
const joinUsLink = `${authUrl}/signup?client_id=${clientId}&response_type=code&redirect_uri=${loginCallback}`;
const publicApiUrl = process.env.REACT_APP_PUBLIC_API_URL;
const privateApiUrl = process.env.REACT_APP_API_URL;
const authTokenUrl = `${authUrl}/oauth2/token`;
const orderStatus = ['PENDING', 'PROCESSING', 'SHIPPED', 'IN_TRANSIT', 'DELIVERED', 'COMPLETED', 'PARTIALLY_REFUNDED', 'REFUNDED'];
const currency = process.env.REACT_APP_CURRENCY;
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL;
const refreshParameterBody = `grant_type=refresh_token&client_id=${clientId}`;
const orderStatusInfo = [
  {
    name: 'PENDING',
    description: 'Order is not acknowledge by seller due to pending payment',
  },
  {
    name: 'PROCESSING',
    description: 'Order is acknowledge by seller',
  },
  {
    name: 'SHIPPED',
    description: 'Order is packaged and left the seller',
  },
  {
    name: 'IN_TRANSIT',
    description: 'Order is in transit',
  },
  {
    name: 'DELIVERED',
    description: 'Order is del  ivered to buyer',
  },
  {
    name: 'COMPLETED',
    description: 'Order is completed',
  },
  {
    name: 'PARTIALLY_REFUNDED',
    description: 'Seller gave a partial refund due to buyer\'s request',
  },
  {
    name: 'REFUNDED',
    description: 'Seller gave a full refund due to buyer\'s request',
  },
];

const prices = [
  {
    name: `${currencySymbol}1 to ${currencySymbol}50`,
    value: '1-50',
  },
  {
    name: `${currencySymbol}51 to ${currencySymbol}200`,
    value: '51-200',
  },
  {
    name: `${currencySymbol}201 to ${currencySymbol}1000`,
    value: '201-1000',
  },
];
const ignoreShipping = process.env.REACT_APP_IGNORE_SHIPPING === 'false' ? false : process.env.REACT_APP_IGNORE_SHIPPING === 'true' ? true : false;
const guestCheckout = process.env.REACT_APP_GUEST_CHECKOUT === 'false' ? false : process.env.REACT_APP_GUEST_CHECKOUT === 'true' ? true : false;
const shippingCost = process.env.REACT_APP_SHIPPING_COST;
const freeShippingThreshold = process.env.REACT_APP_FREE_SHIPPING_THRESHOLD;
export {
  shippingCost,
  freeShippingThreshold,
  guestCheckout,
  shippingLink,
  ignoreShipping,
  prices,
  orderStatusInfo,
  authUrl,
  clientId,
  currency,
  currencySymbol,
  loginCallback,
  loginLink,
  joinUsLink,
  publicApiUrl,
  authTokenUrl,
  privateApiUrl,
  orderStatus,
  refreshParameterBody,
  placeOrderLink,
};
