import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import { privateApiUrl, orderStatus } from '../config';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import { Form, Button, Accordion, Col, Row } from 'react-bootstrap';
import { Buffer } from 'buffer';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'DELETE_REQUEST':
      return { ...state, loadingDelete: true, successDelete: false };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case 'DELETE_FAIL':
      return { ...state, loadingDelete: false };
    case 'DELETE_RESET':
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};
export default function OrderListScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state } = useContext(Store);
  const { userInfo } = state;
  // const { orderStatus, setOrderStatus } = useState('');
  const sp = new URLSearchParams(search); // /admin/orders?userEmail=all
  const [filter, setFilter] = useState('');
  const [orderIdState, setOrderIdState] = useState(sp.get('orderId') || '');
  const [orderStatusState, setOrderStatusState] = useState(sp.get('orderStatus') || '');
  const [userEmailState, setUserEmailState] = useState(
    sp.get('userEmail')
    ?
    Buffer.from(sp.get('userEmail'), 'base64').toString('ascii') : '');
  const [sortOrderState, setSortOrderState] = useState(sp.get('order') || 'newest');
  const [pageState, setPageState] = useState(sp.get('page') || '1');
  const [{ loading, error, orders, loadingDelete, successDelete, countOrders, page, pages }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const { data } = await axios.get(
  //         `${privateApiUrl}/orders/search?page=${pageState}&orderId=${spOrderId}&userEmail=${spUserEmail}&orderStatus=${spOrderStatus}&order=${spSortOrder}`,
  //         {headers: { Authorization: `Bearer ${userInfo.id_token}` }},
  //       );
  //       dispatch({ type: 'FETCH_SUCCESS', payload: data });
  //     } catch (err) {
  //       dispatch({
  //         type: 'FETCH_FAIL',
  //         payload: getError(error),
  //       });
  //     }
  //   };
  //   fetchData();
  // }, [spUserEmail, error, spOrderId, spOrderStatus, spSortOrder]);

  const searchApi = async (params) => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const queryParams = params;
      const { data } = await axios.get(`${privateApiUrl}/orders/search${queryParams}`, {
        headers: { Authorization: `Bearer ${userInfo.id_token}` },
      });
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
        payload: getError(err),
      });
    }
  }
  useEffect(() => {
    const base64Email = userEmailState ? Buffer.from(userEmailState).toString('base64') : '';
    const queryParams = `?page=${pageState}&orderId=${orderIdState}&userEmail=${base64Email}&orderStatus=${orderStatusState}&order=${sortOrderState}`;
    const fetchData = async () => {
      await searchApi(queryParams);
    };
    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' });
    } else {
      fetchData();
    }
    navigate(`/admin/orders${queryParams}`);
  }, [userInfo, successDelete, pageState, sortOrderState]);

  const submitHandler = async () => {
    const base64Email = userEmailState ? Buffer.from(userEmailState).toString('base64') : '';
    const queryParams = `?page=${pageState}&orderId=${orderIdState}&userEmail=${base64Email}&orderStatus=${orderStatusState}&order=${sortOrderState}`;
    const fetchData = async () => {
      await searchApi(queryParams);
    };
    setPageState(1);
    fetchData();
  }

  const resetHandler = async () => {
    setSortOrderState('createdAt');
    setOrderIdState('');
    setUserEmailState('');
    setOrderStatusState('');
    navigate('/admin/orders');
    const fetchData = async () => {
      await searchApi('');
    };
    fetchData();
  }

  const deleteHandler = async (order) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      try {
        dispatch({ type: 'DELETE_REQUEST' });
        await axios.delete(`${privateApiUrl}/orders/${order._id}`, {
          headers: { Authorization: `Bearer ${userInfo.id_token}` },
        });
        toast.success('order deleted successfully');
        dispatch({ type: 'DELETE_SUCCESS' });
      } catch (err) {
        toast.error(getError(error));
        dispatch({
          type: 'DELETE_FAIL',
        });
      }
    }
  };

  const handlePage = async (count) => {
    setPageState(count);
  }

  const handleSortOrder = async (param) => {
    setSortOrderState(param);
    submitHandler();
  }

  return (
    <div>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <h1>Orders</h1>
      <Row>
        <Col md={8}>
          <Accordion>
            <Accordion.Item>
              <Accordion.Header>Filter</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col >
                    <Form.Group className="form-outline mb-3" controlId="formOrderId">
                      <Form.Label>Order ID</Form.Label>
                      <Form.Control
                        value={orderIdState}
                        onChange={(e) => setOrderIdState(e.target.value)}
                        type="orderId"
                        placeholder="Enter Full Order ID" />
                    </Form.Group>
                  </Col>
                  <Col >
                    <Form.Label>Order Status</Form.Label>
                    <Form.Select
                      value={orderStatusState}
                      onChange={(e) => setOrderStatusState(e.target.value)}>
                        <option value="all">All</option>
                        {orderStatus.map((item) => (
                          <option key={item} value={item}>{item}</option>
                        ))}
                    </Form.Select>
                </Col>
                </Row>
                <Row>
                  <Form.Group className="form-outline mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      value={userEmailState}
                      onChange={(e) => setUserEmailState(e.target.value)}
                      type="email" placeholder="Enter email" />
                  </Form.Group>
                </Row>
                <Row>
                  <Col >
                    <Button
                      className="float-end mx-2"
                      variant="info"
                      type="submit"
                      onClick={submitHandler}
                    >Search</Button> &nbsp;
                    <Button
                      className="float-end mx-2"
                      variant="dark"
                      type="reset"
                      onClick={resetHandler}
                    >Reset</Button> &nbsp;

                  </Col>

                </Row>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </Col>
      </Row>
      {loadingDelete && <LoadingBox></LoadingBox>}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (

        <table className="table">
          <thead>
            <tr>
            Result: {countOrders}
            </tr>
            <tr>
              <th>ID</th>

              <th onClick={() => handleSortOrder(sortOrderState === 'abc' ? 'cba' : 'abc')}>
                USER {' '}
                {sortOrderState === 'abc' && (<i className="fa fa-caret-down" />)}
                {sortOrderState === 'cba' && (<i className="fa fa-caret-up" />)}
              </th>
              <th onClick={() => handleSortOrder(sortOrderState === 'newest' ? 'oldest' : 'newest')}>
                DATE {' '}
                {sortOrderState === 'newest' && (<i className="fa fa-caret-down" />)}
                {sortOrderState === 'oldest' && (<i className="fa fa-caret-up" />)}
              </th>
              <th onClick={() => handleSortOrder(sortOrderState === 'highest' ? 'lowest' : 'highest')}>
                TOTAL {' '}
                {sortOrderState === 'highest' && (<i className="fa fa-caret-down" />)}
                {sortOrderState === 'lowest' && (<i className="fa fa-caret-up" />)}
              </th>
              <th>PAID</th>
              <th onClick={() => handleSortOrder(sortOrderState === 'statusHigh' ? 'statusLow' : 'statusHigh')}>
                STATUS {' '}
                {sortOrderState === 'statusHigh' && (<i className="fa fa-caret-down" />)}
                {sortOrderState === 'statusLow' && (<i className="fa fa-caret-up" />)}
              </th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={`${order._id}-${index}`}>
                <td>{order._id}</td>
                <td>{order.user ? order.userEmail : 'DELETED USER'}</td>
                <td>{order.createdAt.substring(0, 10)}</td>
                <td>{order.totalPrice.toFixed(2)}</td>
                <td>{order.isPaid ? order.paidAt.substring(0, 10) : 'No'}</td>

                <td>
                  {order.status}

                </td>
                <td>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => {
                      navigate(`/admin/order/${order._id}`);
                    }}
                  >
                    Details
                  </Button>
                  &nbsp;
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => deleteHandler(order)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
         </tbody>
        </table>

      )}
      <div>
        {[...Array(pages).keys()].map((x, index) => (
            <Button
              key={`${x}-${index}`}
              className={Number(page) === x + 1 ? 'bg-primary' : ''}
              variant="light"
              onClick={() => handlePage(x + 1)}
            >
              {x + 1}
            </Button>
        ))}
      </div>
    </div>
  );
}
