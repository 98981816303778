import Axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Button, Card, ListGroup, Accordion } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { checkAuth, getError } from '../utils';
import { Store } from '../Store';
import CheckoutSteps from '../components/CheckoutSteps';
import LoadingBox from '../components/LoadingBox';
import { currencySymbol, ignoreShipping, privateApiUrl, placeOrderLink, guestCheckout, publicApiUrl, freeShippingThreshold, shippingCost } from '../config';
import AddNotes from '../components/AddNotes';
import SalePrice from '../components/SalePrice';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_SUCCESS':
      return { ...state, loading: false };
    case 'CREATE_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function PlaceOrderScreen() {
  const navigate = useNavigate();
  const urlPath = useLocation();
  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const [notes, setNotes] = useState('');

  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; // 123.2345 => 123.23
  cart.itemsPrice = round2(
    cart.cartItems.reduce((a, c) => a + (c.salePrice ? c.quantity * c.salePrice : c.quantity * c.price), 0)
  );
  cart.shippingPrice = cart.itemsPrice > freeShippingThreshold ? round2(0) : round2(shippingCost);
  cart.taxPrice = round2(0.15 * cart.itemsPrice);
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;

  const placeOrderHandler = async () => {
    try {
      dispatch({ type: 'CREATE_REQUEST' });
      const apiUrl = userInfo ? `${privateApiUrl}/orders` : `${publicApiUrl}/orders`;
      const headers = userInfo ? {
        headers: {
          authorization: `Bearer ${userInfo.id_token}`,
        },
      } : {};
      const { data } = await Axios.post(
        apiUrl,
        {
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
          notes,
        },
        headers,
      );
      ctxDispatch({ type: 'CART_CLEAR' });
      dispatch({ type: 'CREATE_SUCCESS' });
      localStorage.removeItem('cartItems');
      navigate(`/order/${data.order._id}`);
    } catch (err) {
      dispatch({ type: 'CREATE_FAIL' });
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (!cart.paymentMethod) {
      navigate('/payment');
    }
  }, [cart, navigate]);

  useEffect(() => {
    if (!cart.shippingAddress.address && !ignoreShipping) {
      toast.info('Shipping required.');
      setTimeout(() => {
        navigate('/shipping');
      }, 500);
      return;
    }
    const { search } = urlPath;
    const newUrl = window.location.href.replace(search, '');
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get('code');
    if (guestCheckout && !code) {
      return;
    }
    checkAuth({
      urlPath,
      userInfo,
      ctxDispatch,
      toast,
      callbackUrl: placeOrderLink,
      shouldRedirect: true,
      guestCheckout,
    });
  }, []);
  const handleFormInput = (data) => {
    setNotes(data);
  };
  return (
    <div>
      <Row>
        <Col md={5}>
          <CheckoutSteps step1 step2 step3></CheckoutSteps>
        </Col>
      </Row>

      <Helmet>
        <title>Preview Order</title>
      </Helmet>
      <h1 className="my-3">Preview Order</h1>
      <Row>
        <Col md={8}>
          { !ignoreShipping &&
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Shipping</Card.Title>
              <Card.Text>
                <strong>Name:</strong> {cart.shippingAddress.fullName} <br />
                <strong>Address: </strong> {cart.shippingAddress.address},
                {cart.shippingAddress.city}, {cart.shippingAddress.postalCode},
                {cart.shippingAddress.country}
              </Card.Text>
              <Link to="/shipping">Edit</Link>
            </Card.Body>
          </Card>
          }

          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Payment</Card.Title>
              <Card.Text>
                <strong>Method:</strong>&nbsp;
                <i className="fa-2x fa-brands fa-cc-paypal" />
              </Card.Text>
              {/* <Link to="/payment">Edit</Link> */}
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Items</Card.Title>
              <ListGroup variant="flush">
                {cart.cartItems.map((item, index) => (
                  <ListGroup.Item key={`${item._id}-${index}`}>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="img-fluid rounded img-thumbnail"
                        ></img>{' '}
                        <br />
                        <Link to={`/product/${item.slug}`}>{item.name} {item.variant && item.variant.toUpperCase()}</Link>
                      </Col>
                      <Col md={3}>
                        Quantity: <span>{item.quantity}</span>
                      </Col>
                      <Col md={3}>
                        <SalePrice item={item} />
                        <div>total: {currencySymbol}{item.salePrice ? item.salePrice * item.quantity : item.price * item.quantity}</div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <Link to="/cart">Edit</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <AddNotes notes={notes} handleFormInput={handleFormInput} />
          <Card>
            <Card.Body>
              <Card.Title>Order Summary</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Items</Col>
                    <Col>{currencySymbol}{cart.itemsPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Shipping</Col>
                    <Col>{currencySymbol}{cart.shippingPrice.toFixed(2)}</Col>
                  </Row>
                  <Row>
                  <Col><span style={{fontSize: "12px"}}>Free Shipping on orders over {currencySymbol}{freeShippingThreshold}</span></Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Tax</Col>
                    <Col>{currencySymbol}{cart.taxPrice.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <strong> Order Total</strong>
                    </Col>
                    <Col>
                      <strong>{currencySymbol}{cart.totalPrice.toFixed(2)}</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-grid">
                    <Button
                      type="button"
                      onClick={placeOrderHandler}
                      disabled={cart.cartItems.length === 0}
                    >
                      Place Order
                    </Button>
                  </div>
                  {loading && <LoadingBox></LoadingBox>}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
