import { useContext } from 'react';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MessageBox from '../components/MessageBox';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { shippingLink, authUrl, placeOrderLink, currencySymbol, clientId, guestCheckout, ignoreShipping, loginLink, publicApiUrl } from '../config';
import { toast } from 'react-toastify';
import SalePrice from '../components/SalePrice';

export default function CartScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    userInfo,
    cart: { cartItems },
  } = state;

  const updateCartHandler = async (item, quantity, variant) => {
    const { data } = await axios.get(`${publicApiUrl}/products/${item._id}`);
    let hasError = false;
    if (item.hasVariants) {
      const foundItem = data.variants[variant];
      hasError = foundItem.countInStock < quantity;
    } else {
      hasError = data.countInStock < quantity;
    }
    if (hasError) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity, cartIsOpen: false },
    });
  };
  const removeItemHandler = (item) => {
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
  };

  const checkoutHandler = () => {
    if (!userInfo) {
      toast.info('Redirecting to login...');
      let callbackUrl = shippingLink;
      if (ignoreShipping) {
        callbackUrl = placeOrderLink;
      }
      setTimeout(() => {
        window.location = `${authUrl}/login?client_id=${clientId}&response_type=code&redirect_uri=${callbackUrl}`;
      }, 1000);
      return;
    }
    if (!ignoreShipping) {
      navigate('/shipping');
      return;
    }
    navigate('/placeorder');
  };

  const checkoutGuestHandler = () => {
    navigate('/placeorder');
  };

  return (
    <div>
      <Helmet>
        <title>Shopping Cart</title>
      </Helmet>
      <h1>Shopping Cart</h1>
      <Row>
        <Col md={8}>
          {cartItems.length === 0 ? (
            <MessageBox>
              Cart is empty. <Link to="/">Go Shopping</Link>
            </MessageBox>
          ) : (
            <ListGroup className="bg-dark">
              {cartItems.map((item) => (
                <ListGroup.Item key={item._idVariant || item._id}>
                  <Row className="align-items-center">
                    <Col md={4} xs={3}>
                      <Link to={`/product/${item.slug}`}>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="img-fluid rounded img-thumbnail"
                        ></img>{' '}
                        <br />
                        {item.name} {item.variant && item.variant.toUpperCase()}
                      </Link>
                    </Col>
                    <Col md={5} sm={5} xs={5}>
                      <Button
                        onClick={() =>
                          updateCartHandler(item, item.quantity - 1, item.variant)
                        }
                        style={{ background: "none", border: "none" }}
                        className="mx-0 bg-none border-none"
                        variant="light"
                        disabled={item.quantity === 1}
                      >
                        <i className="fas fa-minus-circle"></i>
                      </Button>{' '}
                      <span>{item.quantity}</span>{' '}
                      <Button
                        style={{ background: "none", border: "none" }}
                        className="mx-0 bg-none border-none"
                        variant="light"
                        onClick={() =>
                          updateCartHandler(item, item.quantity + 1, item.variant)
                        }
                        disabled={item._idVariant ? item.quantity === item.variants[item.variant].countInStock : item.quantity === item.countInStock}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Button>
                    </Col>
                    <Col md={2} sm={2} xs={2}><SalePrice item={item} /></Col>
                    <Col md={1} xs={2}>
                      <Button
                        className="mx-0 bg-none border-none"
                        onClick={() => removeItemHandler(item)}
                        variant="light"
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>
                    Subtotal ({cartItems.reduce((a, c) => a + c.quantity, 0)}{' '}
                    items) : {currencySymbol}
                    {cartItems.reduce((a, c) => a + (c.salePrice ? c.salePrice * c.quantity : c.price * c.quantity), 0)}
                  </h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-grid">
                    {userInfo &&
                      <Button
                        type="button"
                        variant="primary"
                        onClick={checkoutHandler}
                        disabled={cartItems.length === 0}
                      >
                        Proceed to Checkout
                      </Button>
                    }
                    {!userInfo &&
                      <Button
                        type="button"
                        variant="primary"
                        onClick={checkoutHandler}
                        disabled={cartItems.length === 0}
                      >
                        Sign in to Checkout
                      </Button>
                    }
                    {guestCheckout && !userInfo &&
                      <Button
                        type="button"
                        className="mt-2"
                        variant="dark"
                        onClick={checkoutGuestHandler}
                        disabled={cartItems.length === 0}
                      >
                        Checkout as Guest
                      </Button>
                    }

                    <br />
                    <Link className="text-center" to="/">Continue Shopping</Link>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
