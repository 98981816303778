import { Accordion, Form } from 'react-bootstrap';

const AddNotes = ({ disabled, notes, handleFormInput, title }) => {
  return (
    <Accordion className="mb-4">
      <Accordion.Item>
        <Accordion.Header>{ title || "Add Notes Here" }</Accordion.Header>
        <Accordion.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control disabled={disabled} value={notes} onChange={(e) => handleFormInput(e.target.value) } placeholder="notes for your order" as="textarea" rows={3} />
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
};

export default AddNotes;
