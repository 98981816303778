import qs from 'qs';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { authUrl, authTokenUrl, clientId } from './config';

export const getError = (error) => {
  return error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
};

export const checkAuth = async ({
  urlPath, userInfo, ctxDispatch, toast, callbackUrl, shouldRedirect, guestCheckout,
}) => {
  const { search } = urlPath;
  const newUrl = window.location.href.replace(search, '');
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get('code');
  window.history.replaceState('Home', '', newUrl);
  if (!code && !userInfo ) {
    if (shouldRedirect) {
      toast.info('Please login. Redirecting to login...');
      setTimeout(() => {
        window.location.href = `${authUrl}/login?client_id=${clientId}&response_type=code&redirect_uri=${callbackUrl}`;
      }, 1000);
    }
    return;
  }
  if (!code) {
    return;
  }

  const fetchData = async () => {
    try {
      const params = {
        grant_type: 'authorization_code',
        client_id: clientId,
        redirect_uri: callbackUrl,
        code,
      };
      const options = {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(params),
        url: authTokenUrl,
      };
      const { data } = await axios(options);
      // dispatch({ type: 'USER_SIGNIN', payload: data });
      var decoded = jwt_decode(data.id_token);
      const userInfoData = {
        ...data,
        ...decoded,
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfoData));
      // console.log(userInfoData)
      ctxDispatch({ type: 'USER_SIGNIN', payload: userInfoData });
    } catch (err) {
      toast.error(getError(err));
    }
  };
  fetchData();
};
